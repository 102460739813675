import React from 'react'
import styled from 'styled-components'
import { CookieStorage } from 'cookie-storage'

const isBrowser = () => typeof window !== 'undefined'
const cookieStorage = new CookieStorage()

export const getMarkedNumbers = (appVersion) => {
  if (!isBrowser()) return []
  const versionKey = `markedNumbers-${appVersion}`
  const item = cookieStorage.getItem(versionKey)
  return item ? JSON.parse(item) : []
}

export const setMarkedNumbers = (numbers, appVersion) => {
  if (!isBrowser()) return false
  const versionKey = `markedNumbers-${appVersion}`
  return cookieStorage.setItem(versionKey, JSON.stringify(numbers))
}

const Table = styled.table`
  font-family: "Lucida Console", Monaco, monospace;
`
const Header = styled.th`
  text-align: center;
`

const NumberStyle = {
  borderRadius: '50px',
  padding: '0.5rem 0',
  background: 'transparent',
  textAlign: 'center',
  cursor: 'pointer'
}

const SelectedNumberStyle = {
  borderRadius: '50px',
  padding: '0.5rem 0',
  background: 'red',
  textAlign: 'center',
  cursor: 'pointer',
  color: 'white',
  fontWeight: 'bold'
}

export default class BingoCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      markedNumbers: []
    }
  }

  componentDidMount () {
    this.setState({
      markedNumbers: getMarkedNumbers(this.props.version)
    })
  }

  handleClick (number, selected) {
    let markedNumbers = [...this.state.markedNumbers]
    if (selected) {
      markedNumbers = markedNumbers.filter(e => e !== number)
    } else {
      markedNumbers.push(number)
      this.broadcastNumber(number)
    }
    setMarkedNumbers(markedNumbers, this.props.version)
    this.setState({ markedNumbers })
  }

  broadcastNumber (number) {
    const { websocket, broadcast } = this.props
    if (!broadcast || !websocket) return true
    websocket.send(number)
  }

  renderNumber (number) {
    const selected = this.state.markedNumbers.includes(number)
    return (
      <div style={selected ? SelectedNumberStyle : NumberStyle} onClick={() => (this.handleClick(number, selected))}>{number}</div>
    )
  }

  renderRow (index) {
    const { card } = this.props
    const row = card[index]
    return (
      <tr>
        <td>{this.renderNumber(row[0])}</td>
        <td>{this.renderNumber(row[1])}</td>
        <td>{this.renderNumber(row[2])}</td>
        <td>{this.renderNumber(row[3])}</td>
        <td>{this.renderNumber(row[4])}</td>
      </tr>
    )
  }

  renderHeader () {
    return (
      <tr>
        <Header>B</Header>
        <Header>I</Header>
        <Header>N</Header>
        <Header>G</Header>
        <Header>O</Header>
      </tr>
    )
  }

  render () {
    return (
      <Table>
        <thead>
          {this.renderHeader()}
        </thead>
        <tbody>
          {this.props.card.map((value, index) => this.renderRow(index))}
        </tbody>
      </Table>
    )
  }
}
