import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BingoCard from '../components/bingoCard'
import SEO from '../components/seo'

export default function BingoCardPage({ pageContext }) {
  const [latestNumber, setLatestNumber] = useState(null)
  const [websocket, setWebsocket] = useState(null)

  useEffect(() => {
    if (typeof window === "undefined") return false
    let ws = new WebSocket('wss://bingo-websocket.herokuapp.com/ws')
    setWebsocket(ws)

    ws.addEventListener('message', function(e) {
      setLatestNumber(e.data)
      setTimeout(function() {
        setLatestNumber(null)
      },4000)
    })
  }, [])

  const numberTitle = latestNumber ? ` - Number: ${latestNumber}` : ''
  return (
    <Layout title={numberTitle}>
      <SEO title={`Bingo Card - Quiet in the bingo hall!`} />
      <BingoCard
        card={pageContext.numbers}
        version={pageContext.version}
        broadcast={pageContext.broadcast}
        websocket={websocket}
      />
    </Layout>
  )
}
